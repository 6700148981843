export const tileColors: string[] = [
  '#2fa497',
  '#e53232',
  '#c4b24f',
  '#0785f6',
  '#490edb',
//  '#c9d4de',
//  '#c9d4de',
//  '#c9d4de',
//  '#c9d4de',
];

export const dummyTileColor = '#c9d4de';